<template>
  <div>
    <Modal :modalConfig="{ ...modalConfig }" @onClose="onClose" @onOk="onOk">
      <div class="modal-container">
        <div class="transfer-container">
          <el-card shadow="never" class="card-container">
            <div slot="header" class="clearfix">
              <span>所有{{ transferConfig.title }}</span>
            </div>
            <div v-loading="loading">
              <el-input placeholder="请输入" v-model="filterText" clearable> </el-input>
              <el-tree
                v-if="showTree"
                class="filter-tree"
                check-strictly
                node-key="orgCode"
                show-checkbox
                :data="treeData"
                :props="defaultProps"
                highlight-current
                :default-checked-keys="checkedKeys"
                :default-expand-all="defaultExpandAll"
                :expand-on-click-node="false"
                ref="treeRef"
              >
              </el-tree>
            </div>
          </el-card>
        </div>
        <div class="button-container">
          <div class="buttons">
            <el-button @click="addCurrentAndLower"
              >添加当前及以下{{ transferConfig.title }}<i class="el-icon-arrow-right"></i
            ></el-button>
            <el-button @click="addCurrent"
              >添加当前{{ transferConfig.title }}<i class="el-icon-arrow-right"></i
            ></el-button>
            <el-button @click="removeTableData" icon="el-icon-arrow-left">移除</el-button>
          </div>
        </div>
        <div class="transfer-container">
          <el-card shadow="never" class="card-container">
            <div slot="header" class="clearfix">
              <span>已选{{ transferConfig.title }}</span>
            </div>
            <div>
              <vxe-table
                border
                show-overflow
                :data="selectData"
                @checkbox-all="selectAllEvent"
                @checkbox-change="selectChangeEvent"
              >
                <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                <vxe-table-column field="sort" width="60" title="序号">
                  <template v-slot="{ rowIndex }">
                    {{ rowIndex + 1 }}
                  </template>
                </vxe-table-column>
                <vxe-table-column field="orgName" title="名称"></vxe-table-column>
                <vxe-table-column field="permissionSearchType" title="范围">
                  <template v-slot="{ row }">
                    {{ row.permissionSearchType === "1" ? "当前" : "当前及以下" }}
                  </template>
                </vxe-table-column>
              </vxe-table>
            </div>
          </el-card>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { isArray } from 'highcharts';
import Modal from '../modal/components/modal.vue';
import request from '../../utils/request';
import debounce from '../../utils/debounce';

export default {
  components: {
    Modal,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modalConfig: {
        visible: false,
        title: '',
        showFooter: true,
      },
      transferConfig: {
        title: '',
        api: '',
      },
      defaultProps: {
        children: '',
        label: '',
        value: '',
      },
      treeData: [],
      defaultExpandAll: false,
      showTree: true,
      filterText: '',
      selectData: [],
      checkedKeys: [],
      selectTableData: [],
      loading: false,
    };
  },
  watch: {
    modalConfig(val) {
      if (val.visible) {
        this.treeData = [];
        // this.selectData = [];
        // this.checkedKeys = [];
        this.selectTableData = [];
        this.getTreeList();
      }
    },
    tableData(val) {
      console.log(val);
      this.selectData = isArray(val) ? val : [];
      this.checkedKeys = isArray(val) ? val.map((item) => item.orgCode) : [];
    },
    filterText(v) {
      if (v) {
        this.defaultExpandAll = true;
      } else {
        this.defaultExpandAll = false;
      }
      this.showTree = false;
      this.searchDebounce();
    },
  },
  created() {},
  mounted() {
    this.searchDebounce = debounce(() => this.getTreeList(), 500);
  },
  methods: {
    onClose() {
      this.modalConfig.visible = false;
    },
    onOk() {
      this.modalConfig.visible = false;
      this.$emit('onOk', this.selectData);
    },
    selectAllEvent({ checked, records }) {
      this.selectTableData = records;
    },
    selectChangeEvent({ checked, records }) {
      this.selectTableData = records;
    },
    // 获取数据
    getTreeList() {
      const data = {
        orgName: this.filterText,
        enableStatus: '009',
      };
      this.loading = true;
      request.post(this.transferConfig.api, data).then((res) => {
        this.loading = false;
        if (res.success) {
          this.treeData = this.setDisabled(res.result);
          this.showTree = true;
        }
      }).catch((e) => {
        this.loading = false;
      });
    },
    // 获取选中数据
    getCheckedNodes() {
      return this.$refs.treeRef.getCheckedNodes();
    },
    // 添加当前及以下组织
    addCurrentAndLower() {
      const disabledKeys = this.getCheckedNodes().filter((v) => !this.checkedKeys.includes(v.orgCode));
      const tempArray = disabledKeys.map((v) => {
        const item = v;
        item.permissionSearchType = '2';
        return item;
      });
      this.selectData = this.selectData.concat(tempArray);
      this.checkedKeys = this.$refs.treeRef.getCheckedKeys();
      this.treeData = this.setDisabled(this.treeData);
    },
    // 添加当前组织
    addCurrent() {
      const disabledKeys = this.getCheckedNodes().filter((v) => !this.checkedKeys.includes(v.orgCode));
      const tempArray = disabledKeys.map((v) => {
        const item = v;
        item.permissionSearchType = '1';
        return item;
      });
      this.selectData = this.selectData.concat(tempArray);
      this.checkedKeys = this.$refs.treeRef.getCheckedKeys();
      this.treeData = this.setDisabled(this.treeData);
    },
    // 设置左侧树禁用
    setDisabled(array) {
      const permissionSearchTypeArr = this.selectData.filter((item) => item.permissionSearchType === '2').map((item) => item.orgCode);
      let tempArray = [];
      tempArray = array.map((v) => {
        const item = v;
        if (this.checkedKeys.includes(item.orgCode)) {
          item.disabled = true;
          if (permissionSearchTypeArr.includes(item.orgCode) && item.children && item.children.length) {
            this.setDisabledScope(item.children);
          }
        } else {
          item.disabled = false;
        }
        if (item.children && item.children.length) {
          this.setDisabled(item.children);
        }
        return item;
      });
      return tempArray;
    },
    // 设置当前及以下为禁用
    setDisabledScope(array) {
      array.map((v) => {
        const item = v;
        item.disabled = true;
        this.checkedKeys.push(item.orgCode);
        if (item.children && item.children.length) {
          this.setDisabledScope(item.children);
        }
        return item;
      });
    },
    // 移除
    removeTableData() {
      const selectTableKeys = this.selectTableData.map((item) => item.orgCode);
      this.selectData = this.selectData.filter((item) => !selectTableKeys.includes(item.orgCode));
      this.checkedKeys = this.selectData.map((item) => item.orgCode);
      this.treeData = this.setDisabled(this.treeData);
    },
  },
};
</script>

<style lang="less" scoped>
.modal-container {
  display: flex;
  height: 100%;
  .transfer-container {
    flex: 1 0 0;
    .card-container {
      height: 100%;
      overflow-y: scroll;
    }
  }
  .button-container {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .buttons {
      height: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
