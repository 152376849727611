var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dataAuth-permissionObj" }, [
    _c(
      "div",
      { staticClass: "dataAuth-selectList" },
      [
        _c(
          "selectList",
          {
            attrs: {
              url: _vm.url,
              label: _vm.label,
              propsMessage: _vm.$route.query.roleCode || "",
              type: "permissionDimension",
            },
            on: {
              select: function (item) {
                return this$1.selectRow("permissionDimension", item)
              },
            },
          },
          [
            _c(
              "el-select",
              {
                staticClass: "permission-dimension-select",
                attrs: { size: "small", placeholder: "请选择" },
                on: {
                  change: function (item) {
                    return _vm.changeDimension(item)
                  },
                },
                model: {
                  value: _vm.permissionDimension,
                  callback: function ($$v) {
                    _vm.permissionDimension = $$v
                  },
                  expression: "permissionDimension",
                },
              },
              _vm._l(_vm.permissionDimensionList, function (item) {
                return _c("el-option", {
                  key: item.dictCode,
                  attrs: {
                    label: item.dictValue,
                    value: item.dictCode,
                    disabled: item.disabled,
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c("treeList", {
          ref: "functionCode",
          attrs: {
            title: "菜单",
            url: "/mdm/mdmfunction/findDataPermissionFunctionTree",
            label: "functionName",
            selectFirst: "",
          },
          on: {
            select: function (item) {
              return this$1.selectRow("functionCode", item)
            },
            selectKey: _vm.setFunctionCode,
          },
        }),
        _c("selectList", {
          ref: "listConfig",
          attrs: {
            title: "列表",
            type: "listPage",
            selectFirst: true,
            url: "/mdm/mdmfunctionsub/findAuthorizedSubList",
            label: "functionName",
          },
          on: {
            select: function (item) {
              return this$1.selectRow("listConfig", item)
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dataAuth-config-table" },
      [
        _c(
          "div",
          { staticClass: "dataAuth-config-table-btn" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.save } },
              [_vm._v("保存")]
            ),
            _c(
              "el-button",
              { attrs: { type: "link" }, on: { click: _vm.back } },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
        _c("ConfigTable", {
          ref: "configTable",
          attrs: { editPermission: _vm.editPermission },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }