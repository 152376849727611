var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-view" }, [
    _c("p", { staticClass: "position-view-basic-title" }, [_vm._v("基本信息")]),
    _c("div", { staticClass: "position-view-basic" }, [
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v(
          "权限维度:" + _vm._s(_vm.permissionResource.permissionDimensionName)
        ),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("角色编码:" + _vm._s(_vm.permissionResource.roleCode)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("角色名称:" + _vm._s(_vm.permissionResource.roleName)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("列表名称:" + _vm._s(_vm.permissionResource.listConfigName)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("菜单名称:" + _vm._s(_vm.permissionResource.functionName)),
      ]),
    ]),
    _c("p", [_vm._v("数据权限")]),
    _c("div", { staticClass: "position-view-basic" }, [
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v("查询对象:" + _vm._s(_vm.permissionResource.permissionObjName)),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v(
          "数据来源:" + _vm._s(_vm.permissionResource.permissionResourceName)
        ),
      ]),
      _c("div", { staticClass: "position-view-item" }, [
        _vm._v(
          "查询方式:" + _vm._s(_vm.permissionResource.permissionSearchTypeName)
        ),
      ]),
    ]),
    _c(
      "div",
      [
        _vm.permissionResource.permissionResource === "1" &&
        _vm.permissionResource.permissionObjCode === "position"
          ? _c(
              "vxe-table",
              {
                staticStyle: { marginTop: "10px" },
                attrs: {
                  border: "",
                  "show-overflow": "",
                  data: _vm.permissionResource.dataPermissionPositionVos,
                },
              },
              [
                _c("vxe-table-column", {
                  attrs: {
                    field: "sort",
                    width: "60",
                    align: "center",
                    title: "序号",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var rowIndex = ref.rowIndex
                          return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                        },
                      },
                    ],
                    null,
                    false,
                    1895642859
                  ),
                }),
                _c("vxe-table-column", {
                  attrs: {
                    field: "positionCode",
                    align: "center",
                    title: "职位编码",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    field: "positionName",
                    align: "center",
                    title: "职位名称",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    field: "orgName",
                    align: "center",
                    title: "所属组织",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    field: "fullName",
                    align: "center",
                    title: "用户名",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.permissionResource.permissionResource === "1" &&
        _vm.permissionResource.permissionObjCode === "org"
          ? _c(
              "vxe-table",
              {
                staticStyle: { marginTop: "10px" },
                attrs: {
                  border: "",
                  "show-overflow": "",
                  data: _vm.permissionResource.dataPermissionOrgVos,
                },
              },
              [
                _c("vxe-table-column", {
                  attrs: {
                    field: "sort",
                    width: "60",
                    align: "center",
                    title: "序号",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var rowIndex = ref.rowIndex
                          return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                        },
                      },
                    ],
                    null,
                    false,
                    1895642859
                  ),
                }),
                _c("vxe-table-column", {
                  attrs: {
                    field: "orgCode",
                    align: "center",
                    title: "组织编码",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    field: "orgName",
                    align: "center",
                    title: "组织名称",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    field: "orgTypeName",
                    align: "center",
                    title: "组织层级",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    field: "permissionSearchType",
                    align: "center",
                    title: "范围",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.permissionSearchType === "1"
                                    ? "当前"
                                    : "当前及以下"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    142567503
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dialog-footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "danger", size: "small", icon: "el-icon-close" },
            on: {
              click: function () {
                _vm.$emit("onClose")
              },
            },
          },
          [_vm._v("关闭 ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }