<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import Modal from '../../../../../components/modal';
import request from '../../../../../utils/request';
import dataAuthView from '../dataAuthView/dataAuthView.vue';
import PermissionObj from '../permission_obj/permissionObj.vue';
import ListPageAuthorization from '../list_page_authorization/listPageAuthorization.vue';

export default {
  extends: TablePage,
  components: {
    Modal,
    dataAuthView,
    PermissionObj,
    ListPageAuthorization,
  },
  data() {
    return {
      configs,
      modalConfig: {
        visible: false,
        type: '',
        width: '80%',
      },
    };
  },
  created() {
    this.getConfigList('data_auth_table');
  },
  mounted() {
    if (this.$route.query.roleCode) {
      this.$nextTick(() => {
        this.formName = 'PermissionObj';
        this.modalConfig = { ...this.modalConfig, width: '85%', title: '对象授权' };
        this.openDrawer();
      });
    }
  },
  watch: {
    $route() {
      if (this.$route.query.roleCode) {
        this.$nextTick(() => {
          this.formName = 'PermissionObj';
          this.modalConfig = { ...this.modalConfig, width: '85%', title: '对象授权' };
          this.openDrawer();
        });
      }
    },
  },
  methods: {

    // 关闭弹窗
    closeModal() {
      this.modalConfig = {};
      this.modalFormData = {};
      this.formConfig = {};
      this.modalFormData = {}; // 清空弹窗中表单的数据
      this.modalConfig = { ...this.modalConfig, visible: false, type: undefined };
      // 附加操作 角色管理跳过来的，关闭就返回去
      if (this.$route.query.roleCode) {
        this.$router.back();
        this.$store.commit('closeTag', this.$route);
      }
    },

    modalClick({ val, row }) {
      this.formConfig = {};
      switch (val.code) {
        case 'object_authorization':
          this.formName = 'PermissionObj';
          this.modalConfig = { ...this.modalConfig, width: '85%', title: '对象授权' };
          this.openDrawer();
          break;
        case 'list_authorization':
          this.formName = 'ListPageAuthorization';
          this.modalConfig = { ...this.modalConfig, width: '85%', title: '列表授权' };
          this.openDrawer();
          break;
        case 'view':
          this.formName = 'dataAuthView';
          this.modalConfig = { ...this.modalConfig, width: '40%', title: '权限查看' };
          this.formConfig = { ...val, ...row };
          this.openDrawer();
          break;
        default:
          break;
      }
    },
    enableOrDiable(type) {
      const datas = [];
      let message = '启用';
      let url = '/mdm/mdmDataPermissionController/enable';
      this.selectRow.forEach((v) => {
        datas.push(v.id);
      });
      if (type === 'disable') {
        url = '/mdm/mdmDataPermissionController/disable';
        message = '禁用';
      }
      this.$confirm(`确定${message}该权限？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        request.post(url, datas)
          .then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: `${message}成功`,
              });
              this.getList();
            }
          });
      });
    },
  },
};
</script>
