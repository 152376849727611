<template>
  <div class ="dataAuth-permissionObj">
    <div class = "dataAuth-selectList">
      <treeList
        title = "菜单"
        url="/mdm/mdmfunction/findDataPermissionFunctionTree"
        label = "functionName"
        @select = "(item)=>this.selectRow('functionCode',item)"
         @selectKey =  "setFunctionCode"
        selectFirst
        />
      <selectList
        title = "列表"
        type = "listPage"
        :selectFirst="true"
        url="/mdm/mdmfunctionsub/findAuthorizedSubList"
        @select = "(item)=>this.selectRow('listConfig',item)"
        label = "functionName"
        ref = "listConfig"/>
        <selectList
        title = "权限角色"
        url ="/mdm/mdmDataPermissionController/roleSelectList"
        label = "roleName"
         ref = "permissionDimension"
        type = 'permissionDimension'
        @select = "(item)=>this.selectRow('permissionDimension',item)"
      >
        <el-select v-model="permissionDimension" @change = "(item)=>changeDimension(item)" class="permission-dimension-select" size="small" placeholder="请选择">
          <el-option
            v-for="item in permissionDimensionList"
            :key="item.dictCode"
            :label="item.dictValue"
            :value="item.dictCode"
            :disabled = "item.disabled"
            >
          </el-option>
        </el-select>
      </selectList>
    </div>
    <div class="dataAuth-config-table">
      <div class = "dataAuth-config-table-btn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button type="link"  @click="back">返回</el-button>
      </div>
      <ConfigTable ref = "configTable" :editPermission="editPermission"/>
    </div>
  </div>
</template>

<script>
import request from '../../../../../utils/request';
import selectList from '../selectList/selectList.vue';
import treeList from '../treeList/treeList.vue';
import ConfigTable from '../configTable/configTable.vue';

export default {

  components: {
    selectList,
    ConfigTable,
    treeList,
  },
  data() {
    return {
      editType: 'add',
      functionCode: '',
      roleCode: '',
      listConfigCode: '',
      permissionDimension: 'role',
      permissionDimensionList: [],
      editPermission: {},
      functionCodeList: [],
      allListData: {},
    };
  },
  watch: {
    functionCode() {
      this.$refs.listConfig.getListData({ parentCode: this.functionCode });
      this.$refs.permissionDimension.getListData({ functionCode: this.functionCode });
    },
  },
  created() {
    console.log(11);
    request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'permission_dimension' }).then((res) => {
      if (res.success) {
        const Array = res.result && res.result.map((item) => {
          if (item.dictCode !== 'role') {
            return { ...item, disabled: true };
          }
          return item;
        });
        this.permissionDimensionList = Array;
      }
    });
  },
  methods: {
    selectRow(type, item) {
      this.allListData[`${this.roleCode}-${this.functionCode}-${this.listConfigCode}`] = {
        ...(Array.isArray(this.$refs.configTable.dataList)
          && this.$refs.configTable.dataList.length > 0
          && this.$refs.configTable.dataList[0]),
        dataPermissionPositionVos: this.$refs.configTable.positionFixData,
        dataPermissionOrgVos: this.$refs.configTable.orgFixData,
      };
      switch (type) {
        case 'functionCode':
          this.selectFuncCode(item);
          break;
        case 'listConfig':
          this.selectListConfig(item);
          break;
        case 'permissionDimension':
          this.selectPermissionDimension(item);
          break;
        default:
          break;
      }

      this.getSearchType();
    },
    setFunctionCode(functionCodeList) {
      this.functionCodeList = functionCodeList;
    },
    getSearchType() {
      if (this.roleCode && this.functionCode && this.listConfigCode) {
        this.$refs.configTable.getPermissionObjCodeList(this.functionCode, this.listConfigCode);
        // if (this.allListData[`${this.roleCode}-${this.functionCode}-${this.listConfigCode}`]) {
        //   this.editPermission = this.allListData[`${this.roleCode}-${this.functionCode}-${this.listConfigCode}`];
        //   return;
        // }
        request.post('/mdm/mdmDataPermissionController/queryCondition', {
          permissionDimension: this.permissionDimension,
          roleCode: this.roleCode,
          functionCode: this.functionCode,
          listConfigCode: this.listConfigCode,
        }).then((res) => {
          if (res.success) {
            if (res.result) {
              this.editType = 'update';
              this.editPermission = res.result;
            } else {
              this.editType = 'add';
              this.editPermission = {
                permissionObjCode: '',
                permissionResource: '',
                permissionSearchType: '',
                dataPermissionChannelVos: [],
                dataPermissionCustomerOrgVos: [],
                dataPermissionCustomerVos: [],
                dataPermissionOrgVos: [],
                dataPermissionPositionVos: [],
                dataPermissionTerminalVos: [],
              };
            }
          }
        });
      }
    },

    selectFuncCode(item) {
      this.functionCode = item.functionCode;
    },
    selectListConfig(item) {
      this.listConfigCode = item.functionCode;
    },
    selectPermissionDimension(item) {
      switch (this.permissionDimension) {
        case 'role':
          this.roleCode = item.roleCode;
          break;

        default:
          break;
      }
    },
    changeDimension(item) {
      console.log(item);
    },
    save() {
      const config = this.$refs.configTable.getTableData();
      if (!this.roleCode) {
        this.$message({
          type: 'warn',
          message: '请选择角色',
        });
        return;
      }
      if (!this.listConfigCode) {
        this.$message({
          type: 'warn',
          message: '请选择列表',
        });
        return;
      }
      if (!config.data[0].permissionObjCode || !config.data[0].permissionResource) {
        if (config.data[0].permissionResource === '0' && !config.data[0].permissionSearchType) {
          this.$message({
            type: 'warn',
            message: '请配置数据权限',
          });
        }

        return;
      }
      if (config.data[0].permissionObjCode === 'position' && config.data[0].permissionResource === '1' && config.positionFixData.length === 0) {
        this.$message({
          type: 'warn',
          message: '请配置数据权限',
        });
        return;
      }
      if (config.data[0].permissionObjCode === 'org' && config.data[0].permissionResource === '1' && config.orgFixData.length === 0) {
        this.$message({
          type: 'warn',
          message: '请配置数据权限',
        });
        return;
      }
      let url = '/mdm/mdmDataPermissionController/updateAllSub';
      let msg = '新增';
      let params = {
        permissionDimension: this.permissionDimension,
        roleCode: this.roleCode,
        functionCodeList: [this.functionCode],
        functionCode: this.functionCode,
        listConfigCode: this.listConfigCode,
        ...config.data[0],
        dataPermissionPositionVos: config.positionFixData && config.positionFixData.length ? config.positionFixData : null,
        dataPermissionOrgVos: config.orgFixData && config.orgFixData.length ? config.orgFixData : null,
      };

      if (this.editType === 'update') {
        params = {
          ...this.editPermission,
          functionCodeList: [this.functionCode],
          ...config.data[0],
          dataPermissionPositionVos: config.positionFixData && config.positionFixData.length ? config.positionFixData : null,
          dataPermissionOrgVos: config.orgFixData && config.orgFixData.length ? config.orgFixData : null,
        };
        url = '/mdm/mdmDataPermissionController/updateAllSub';
        msg = '更新';
      }

      request.post(url, params).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: `${msg}成功`,
          });
          // this.$emit('onClose');
          // this.$emit('onGetList');
        }
      });
    },
    back() {
      this.$emit('onClose');
    },

  },
};
</script>

<style lang = "less">
.dataAuth-permissionObj{
  display: flex;
}
.permission-dimension-select{
  input{
    background-color:  #EDEFF0;
    border:none;
    font-size: 16px;
  }
  .el-select__caret{
    font-size: 16px  !important;
  }
}
.dataAuth-selectList{
  display: flex;
}
.dataAuth-config-table{
  width: calc(100% - 700px);
  &-btn{
    text-align: right;
    margin-bottom : 10px;
  }
}

</style>
