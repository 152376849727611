var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-table" },
    [
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: { border: "", "show-overflow": "", data: _vm.dataList },
        },
        [
          _c("vxe-table-column", {
            attrs: {
              field: "sort",
              width: "60",
              align: "center",
              title: "序号",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowIndex = ref.rowIndex
                  return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "permissionObjCode",
              align: "center",
              title: "查询对象",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function (item) {
                            return _vm.selectPemissionObj(row, item)
                          },
                        },
                        model: {
                          value: row.permissionObjCode,
                          callback: function ($$v) {
                            _vm.$set(row, "permissionObjCode", $$v)
                          },
                          expression: "row.permissionObjCode",
                        },
                      },
                      _vm._l(_vm.permissionObjCodeList, function (item) {
                        return _c("el-option", {
                          key: item.permissionObjCode,
                          attrs: {
                            label: item.permissionObjName,
                            value: item.permissionObjCode,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "permissionResource",
              align: "center",
              title: "数据来源",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function ($event) {
                            return _vm.permissionResourceChange(row)
                          },
                        },
                        model: {
                          value: row.permissionResource,
                          callback: function ($$v) {
                            _vm.$set(row, "permissionResource", $$v)
                          },
                          expression: "row.permissionResource",
                        },
                      },
                      _vm._l(_vm.permissionResourceList, function (item) {
                        return _c("el-option", {
                          key: item.dictCode,
                          attrs: {
                            label: item.dictValue,
                            value: item.dictCode,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "permissionSearchType",
              width: "180",
              align: "center",
              title: "查询方式",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.permissionResource === "0"
                      ? _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: row.permissionSearchType,
                              callback: function ($$v) {
                                _vm.$set(row, "permissionSearchType", $$v)
                              },
                              expression: "row.permissionSearchType",
                            },
                          },
                          _vm._l(_vm.searchTypeList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        )
                      : row.permissionResource === "1"
                      ? _c("el-button", { on: { click: _vm.addFix } }, [
                          _vm._v("添加固定值"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c(
            "vxe-table-column",
            {
              attrs: {
                field: "operate-btn",
                align: "center",
                width: "60",
                title: "操作",
              },
            },
            [
              [
                _c(
                  "div",
                  { staticClass: "setting-btn" },
                  [
                    _c(
                      "Popconfirm",
                      {
                        attrs: {
                          title: "确定清空该数据？",
                          "confirm-button-type": "text",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.clearRow()
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-delete",
                              staticStyle: { color: "#f5222d" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm.permissionResource.permissionResource === "1" &&
      _vm.permissionResource.permissionObjCode === "position"
        ? _c(
            "vxe-table",
            {
              staticStyle: { marginTop: "10px" },
              attrs: {
                border: "",
                "show-overflow": "",
                data: _vm.positionFixData,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: {
                  field: "sort",
                  width: "60",
                  align: "center",
                  title: "序号",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                      },
                    },
                  ],
                  null,
                  false,
                  1895642859
                ),
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "positionCode",
                  align: "center",
                  title: "职位编码",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "positionName",
                  align: "center",
                  title: "职位名称",
                },
              }),
              _c("vxe-table-column", {
                attrs: { field: "orgName", align: "center", title: "所属组织" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "fullName", align: "center", title: "用户名" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.permissionResource.permissionResource === "1" &&
      _vm.permissionResource.permissionObjCode === "org"
        ? _c(
            "vxe-table",
            {
              staticStyle: { marginTop: "10px" },
              attrs: { border: "", "show-overflow": "", data: _vm.orgFixData },
            },
            [
              _c("vxe-table-column", {
                attrs: {
                  field: "sort",
                  width: "60",
                  align: "center",
                  title: "序号",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                      },
                    },
                  ],
                  null,
                  false,
                  1895642859
                ),
              }),
              _c("vxe-table-column", {
                attrs: { field: "orgCode", align: "center", title: "组织编码" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "orgName", align: "center", title: "组织名称" },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "orgTypeName",
                  align: "center",
                  title: "组织层级",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "permissionSearchType",
                  align: "center",
                  title: "范围",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.permissionSearchType === "1"
                                  ? "当前"
                                  : "当前及以下"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  142567503
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.callBackFn },
      }),
      _c("TreeTransfer", {
        ref: "treeTransfer",
        attrs: { tableData: _vm.orgFixData },
        on: { onOk: _vm.transferOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }