<template>
  <div class="selectListContent">
    <div v-if="type !== 'permissionDimension'" class="select-list-title">
      {{ title }}
    </div>
    <div v-else class="select-list-title">
      <slot />
    </div>
    <div>
      <el-input
        v-model="message"
        placeholder="请输入搜索内容"
        suffix-icon="el-icon-search"
        @input="search"
        clearable
      ></el-input>
    </div>
    <div v-if="dataList.length !== 0" class="seleceListBox">
      <el-tree
        class="filter-tree"
        :data="dataList"
        :props="defaultProps"
        highlight-current
        show-checkbox
        :default-expand-all="false"
        :expand-on-click-node="false"
        node-key="functionCode"
        @node-click="selectItem"
        @check="checkChange"
        :default-checked-keys="checkedKeys"
      >
        <span class="el-tree-node__label" slot-scope="{ data }">
          <div
            class="tree_label_text"
            :class="data.functionPermission === '0' ? 'disabled-text' : ''"
          >
            {{ data.functionName }}
          </div>
        </span>
      </el-tree>
    </div>
    <div v-else class="selectList-empty">
      当前暂无数据
    </div>
  </div>
</template>

<script>
import request from '../../../../../utils/request';
import debounce from '../../../../../utils/debounce';

export default {
  props: {
    url: String,
    title: String,
    label: String,
    selectFirst: Boolean,
    type: String,
    propsMessage: String,
  },
  data() {
    return {
      originDataList: [], // 远程获取的数据
      dataList: [],
      message: '',
      selectRow: {},
      defaultProps: {
        children: 'children',
        label: 'functionName',
        value: 'functionCode',
      },
      roleCode: '',
      checkedKeys: [],
    };
  },
  watch: {
    url() {
      this.getListData({});
    },
  },
  async created() {
    this.roleCode = this.$route.query.roleCode;
    if (this.type !== 'listPage' && !this.roleCode) {
      await this.getListData();
      console.log('22222');
      console.log(this.selectFirst);
      if (this.selectFirst) {
        this.selectRow = { ...this.dataList[0] };
        this.$emit('select', this.dataList[0]);
      }
    }
  },
  mounted() {},
  methods: {
    async getListData(params) {
      const data = params || {};
      data.functionName = this.message;
      this.roleCode = (params && params.roleCode) || this.roleCode;
      data.roleCode = (params && params.roleCode) || this.roleCode;
      console.log(this.roleCode);
      const res = await request.post(this.url, data);
      if (res.success) {
        this.originDataList = res.result;
        if (this.type === 'listPage') {
          this.originDataList = res.result.filter((v) => v.functionType === '1');
        }
        this.dataList = this.dealTreeData(this.originDataList);
        if (this.propsMessage && this.type === 'permissionDimension') {
          this.$nextTick(() => {
            this.message = this.dataList.find((a) => a.roleCode === this.propsMessage)[this.functionName] || '';
            this.search();
            this.selectItem(this.dataList.find((a) => a.roleCode === this.propsMessage) || {});
          });
        }
      }
      return true;
    },
    /**
     * 设置禁用和默认选中
     */
    dealTreeData(data) {
      return data.map((v) => {
        const item = v;
        if (item.functionPermission === '0') {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
        if (item.functionAuthorized === '1') {
          this.checkedKeys.push(item.functionCode);
        }
        if (item.children.length > 0) {
          this.dealTreeData(item.children);
        }
        return item;
      });
    },
    selectItem(item) {
      console.log(item);
      if (item.functionPermission === '0') return;
      this.selectRow = item;
      this.$emit('select', item);
    },
    checkChange(e, key) {
      this.$emit('selectKey', key.checkedKeys);
    },
    // eslint-disable-next-line
    search: debounce(function() {
      // this.dataList = this.originDataList.filter((item) => item[this.functionName].toLowerCase().indexOf(this.message.toLowerCase()) >= 0);
      this.getListData();
    }, 500),
  },
};
</script>

<style lang="less">
.selectListContent {
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid #e4e4e4;
  .select-list-title {
    padding-left: 10px;
    font-size: 16px;
    color: #606266;
    background-color: #edeff0;
    height: 50px;
    line-height: 50px;
  }
  .seleceListBox {
    height: 600px;
    overflow: auto;
    ul {
      padding: 0;
      list-style: none;
      li {
        height: 30px;
        font-size: 12px;
        color: #606266;
        padding: 0 10px;
        line-height: 30px;
        &:hover {
          background-color: #f1eeee;
        }
      }
      .selectList-selected {
        background-color: #f1eeee !important;
      }
      .selected {
        background-color: #d1e9ff;
      }
    }
  }
  .selectList-empty {
    text-align: center;
    padding: 10px 0;
    font-size: 12px;
    color: #a7a7a7;
  }
  .el-tree-node__label {
    width: 100%;
    height: 100%;
  }
  .tree_label_text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .disabled-text {
    color: rgb(150, 150, 150);
    cursor: not-allowed;
  }
}
</style>
