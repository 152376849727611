var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { modalConfig: Object.assign({}, _vm.modalConfig) },
          on: { onClose: _vm.onClose, onOk: _vm.onOk },
        },
        [
          _c("div", { staticClass: "modal-container" }, [
            _c(
              "div",
              { staticClass: "transfer-container" },
              [
                _c(
                  "el-card",
                  { staticClass: "card-container", attrs: { shadow: "never" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("span", [
                          _vm._v("所有" + _vm._s(_vm.transferConfig.title)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入", clearable: "" },
                          model: {
                            value: _vm.filterText,
                            callback: function ($$v) {
                              _vm.filterText = $$v
                            },
                            expression: "filterText",
                          },
                        }),
                        _vm.showTree
                          ? _c("el-tree", {
                              ref: "treeRef",
                              staticClass: "filter-tree",
                              attrs: {
                                "check-strictly": "",
                                "node-key": "orgCode",
                                "show-checkbox": "",
                                data: _vm.treeData,
                                props: _vm.defaultProps,
                                "highlight-current": "",
                                "default-checked-keys": _vm.checkedKeys,
                                "default-expand-all": _vm.defaultExpandAll,
                                "expand-on-click-node": false,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "button-container" }, [
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c("el-button", { on: { click: _vm.addCurrentAndLower } }, [
                    _vm._v("添加当前及以下" + _vm._s(_vm.transferConfig.title)),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                  ]),
                  _c("el-button", { on: { click: _vm.addCurrent } }, [
                    _vm._v("添加当前" + _vm._s(_vm.transferConfig.title)),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-arrow-left" },
                      on: { click: _vm.removeTableData },
                    },
                    [_vm._v("移除")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "transfer-container" },
              [
                _c(
                  "el-card",
                  { staticClass: "card-container", attrs: { shadow: "never" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("span", [
                          _vm._v("已选" + _vm._s(_vm.transferConfig.title)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "vxe-table",
                          {
                            attrs: {
                              border: "",
                              "show-overflow": "",
                              data: _vm.selectData,
                            },
                            on: {
                              "checkbox-all": _vm.selectAllEvent,
                              "checkbox-change": _vm.selectChangeEvent,
                            },
                          },
                          [
                            _c("vxe-table-column", {
                              attrs: { type: "checkbox", width: "60" },
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                field: "sort",
                                width: "60",
                                title: "序号",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var rowIndex = ref.rowIndex
                                    return [
                                      _vm._v(" " + _vm._s(rowIndex + 1) + " "),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("vxe-table-column", {
                              attrs: { field: "orgName", title: "名称" },
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                field: "permissionSearchType",
                                title: "范围",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.permissionSearchType === "1"
                                              ? "当前"
                                              : "当前及以下"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }