<template>
  <div class="custom-table">
    <vxe-table border show-overflow ref="xTable" :data="dataList">
      <vxe-table-column field="sort" width="60" align="center" title="序号">
        <template v-slot="{ rowIndex }">
          {{ rowIndex + 1 }}
        </template>
      </vxe-table-column>
      <vxe-table-column field="permissionObjCode" align="center" title="查询对象">
        <template v-slot="{ row }">
          <el-select
            v-model="row.permissionObjCode"
            @change="item => selectPemissionObj(row, item)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in permissionObjCodeList"
              :key="item.permissionObjCode"
              :label="item.permissionObjName"
              :value="item.permissionObjCode"
            >
            </el-option>
          </el-select>
        </template>
      </vxe-table-column>
      <vxe-table-column field="permissionResource" align="center" title="数据来源">
        <template v-slot="{ row }">
          <el-select
            v-model="row.permissionResource"
            @change="permissionResourceChange(row)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in permissionResourceList"
              :key="item.dictCode"
              :label="item.dictValue"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </template>
      </vxe-table-column>
      <vxe-table-column field="permissionSearchType" width="180" align="center" title="查询方式">
        <template v-slot="{ row }">
          <el-select
            v-if="row.permissionResource === '0'"
            v-model="row.permissionSearchType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in searchTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button v-else-if="row.permissionResource === '1'" @click="addFix"
            >添加固定值</el-button
          >
        </template>
      </vxe-table-column>
      <vxe-table-column field="operate-btn" align="center" width="60" title="操作">
        <template>
          <div class="setting-btn">
            <Popconfirm title="确定清空该数据？" confirm-button-type="text" @confirm="clearRow()">
              <el-button slot="reference" type="text">
                <i class="el-icon-delete" style="color: #f5222d"></i>
              </el-button>
            </Popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
    <vxe-table
      v-if="
        permissionResource.permissionResource === '1' &&
          permissionResource.permissionObjCode === 'position'
      "
      style="marginTop: 10px"
      border
      show-overflow
      :data="positionFixData"
    >
      <vxe-table-column field="sort" width="60" align="center" title="序号">
        <template v-slot="{ rowIndex }">
          {{ rowIndex + 1 }}
        </template>
      </vxe-table-column>
      <vxe-table-column field="positionCode" align="center" title="职位编码"> </vxe-table-column>
      <vxe-table-column field="positionName" align="center" title="职位名称"> </vxe-table-column>
      <vxe-table-column field="orgName" align="center" title="所属组织"> </vxe-table-column>
      <vxe-table-column field="fullName" align="center" title="用户名"> </vxe-table-column>
    </vxe-table>
    <vxe-table
      v-if="
        permissionResource.permissionResource === '1' &&
          permissionResource.permissionObjCode === 'org'
      "
      style="marginTop: 10px"
      border
      show-overflow
      :data="orgFixData"
    >
      <vxe-table-column field="sort" width="60" align="center" title="序号">
        <template v-slot="{ rowIndex }">
          {{ rowIndex + 1 }}
        </template>
      </vxe-table-column>
      <vxe-table-column field="orgCode" align="center" title="组织编码"> </vxe-table-column>
      <vxe-table-column field="orgName" align="center" title="组织名称"> </vxe-table-column>
      <vxe-table-column field="orgTypeName" align="center" title="组织层级"> </vxe-table-column>
      <vxe-table-column field="permissionSearchType" align="center" title="范围">
        <template v-slot="{ row }">
          {{ row.permissionSearchType === "1" ? "当前" : "当前及以下" }}
        </template>
      </vxe-table-column>
    </vxe-table>
    <SelectConfig ref="selectConfig" @onGetSelect="callBackFn" />
    <TreeTransfer ref="treeTransfer" @onOk="transferOk" :tableData="orgFixData"/>
  </div>
</template>

<script>
import { Popconfirm } from 'element-ui';
import request from '../../../../../utils/request';
import SelectConfig from '../../../../../components/select_org_cus_ter/select_config.vue';
import TreeTransfer from '../../../../../components/transfer/tree_transfer.vue';
// import permissionObjCodeList from './config';

export default {
  name: 'editTable',
  components: {
    Popconfirm,
    TreeTransfer,
    SelectConfig,
  },
  props: {
    editPermission: Object,
  },
  watch: {
    editPermission(val) {
      console.log(this.editPermission);
      if (this.editPermission) {
        this.dataList = [
          {
            permissionObjCode: this.editPermission.permissionObjCode,
            permissionResource: this.editPermission.permissionResource,
            permissionSearchType: this.editPermission.permissionSearchType,
          },
        ];
        this.positionFixData = this.editPermission.dataPermissionPositionVos;
        this.orgFixData = this.editPermission.dataPermissionOrgVos;
        this.selectPemissionObj(this.editPermission, this.editPermission.permissionObjCode);
      } else {
        this.dataList = [
          { permissionObjCode: '', permissionResource: '', permissionSearchType: '' },
        ];
        this.searchTypeList = [];
      }
    },
  },
  data() {
    return {
      permissionResourceList: [],
      permissionObjCodeList: [],
      searchTypeList: [],
      permissionResource: '',
      positionFixData: [],
      orgFixData: [],
      dataList: [{ permissionObjCode: '', permissionResource: '', permissionSearchType: '' }],
    };
  },
  created() {
    // this.getPermissionObjCodeList();
    request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'permission_resource' }).then((res) => {
      if (res.success) {
        console.log('res', res.result);
        this.permissionResourceList = res.result;
      }
    });
  },
  methods: {
    // 获取查询对象字典数据
    getPermissionObjCodeList(functionCode, listConfigCode) {
      request.post('/mdm/mdmDataPermissionController/objList', { functionCode, listConfigCode }).then((res) => {
        if (res.success) {
          this.permissionObjCodeList = res.result;
        }
      });
    },
    // 数据来源change事件
    permissionResourceChange(val) {
      console.log(val);
      this.permissionResource = val;
    },
    // 职位固定值弹窗选择回调
    callBackFn(row) {
      const tempPositionCode = this.positionFixData.map((item) => item.positionCode);
      row.forEach((i) => {
        const item = i;
        if (!tempPositionCode.includes(item.positionCode)) {
          this.positionFixData.push(item);
        }
      });
    },
    // 添加固定值
    addFix() {
      if (this.permissionResource.permissionObjCode === 'position') {
        const params = {
          functionCode: 'data_auth_position_table',
          data: this.positionFixData,
          selectionList: Array.isArray(this.positionFixData) ? this.positionFixData.map((item) => item.positionCode) : [],
          idKey: 'positionCode',
          // paramData: {
          //   enableStatus: '009',
          // },
          choiceType: 'checkbox',
        };
        console.log(params);
        this.$refs.selectConfig.openSelectModal(params);
      }
      if (this.permissionResource.permissionObjCode === 'org') {
        this.$refs.treeTransfer.modalConfig = {
          title: '选择组织',
          visible: true,
          showFooter: true,
          buttons: {
            sure: true, // 确定按钮
            close: true, // 关闭按钮
          },
        };
        this.$refs.treeTransfer.defaultProps = {
          children: 'children',
          label: 'orgName',
          value: 'orgCode',
        };
        this.$refs.treeTransfer.transferConfig = {
          title: '组织',
          api: '/mdm/mdmOrgController/orgSearchTree',
        };
      }
    },
    // 组织固定值确认
    transferOk(data) {
      this.orgFixData = data;
    },
    selectPemissionObj(row, item) {
      console.log(row, item);
      this.permissionResource = row;
      request
        .get('/mdm/mdmDataPermissionController/searchTypeList', { permissionObjCode: item })
        .then((res) => {
          if (res.success) {
            const Array = Object.entries(res.result).map((v) => ({
              value: v[0],
              label: v[1],
            }));
            this.searchTypeList = Array;
          }
        });
    },
    getTableData() {
      return { data: this.dataList, positionFixData: this.positionFixData, orgFixData: this.orgFixData };
    },
    clearRow() {
      this.dataList = [{ permissionObjCode: '', permissionResource: '', permissionSearchType: '' }];
      this.searchTypeList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.custom-table {
  padding-left: 10px;
  box-sizing: border-box;
}
</style>
