<template>
  <div class="position-view">
    <p class="position-view-basic-title">基本信息</p>
    <div class="position-view-basic">
      <div class="position-view-item">权限维度:{{ permissionResource.permissionDimensionName }}</div>
      <div class="position-view-item">角色编码:{{ permissionResource.roleCode }}</div>
      <div class="position-view-item">角色名称:{{ permissionResource.roleName }}</div>
      <div class="position-view-item">列表名称:{{ permissionResource.listConfigName }}</div>
      <div class="position-view-item">菜单名称:{{ permissionResource.functionName }}</div>
    </div>
    <p>数据权限</p>
    <div class="position-view-basic">
      <div class="position-view-item">查询对象:{{ permissionResource.permissionObjName }}</div>
      <div class="position-view-item">数据来源:{{ permissionResource.permissionResourceName }}</div>
      <div class="position-view-item">查询方式:{{ permissionResource.permissionSearchTypeName }}</div>
    </div>
    <div>
      <vxe-table
        v-if="
          permissionResource.permissionResource === '1' &&
            permissionResource.permissionObjCode === 'position'
        "
        style="marginTop: 10px"
        border
        show-overflow
        :data="permissionResource.dataPermissionPositionVos"
      >
        <vxe-table-column field="sort" width="60" align="center" title="序号">
          <template v-slot="{ rowIndex }">
            {{ rowIndex + 1 }}
          </template>
        </vxe-table-column>
        <vxe-table-column field="positionCode" align="center" title="职位编码"> </vxe-table-column>
        <vxe-table-column field="positionName" align="center" title="职位名称"> </vxe-table-column>
        <vxe-table-column field="orgName" align="center" title="所属组织"> </vxe-table-column>
        <vxe-table-column field="fullName" align="center" title="用户名"> </vxe-table-column>
      </vxe-table>
      <vxe-table
        v-if="
          permissionResource.permissionResource === '1' &&
            permissionResource.permissionObjCode === 'org'
        "
        style="marginTop: 10px"
        border
        show-overflow
        :data="permissionResource.dataPermissionOrgVos"
      >
        <vxe-table-column field="sort" width="60" align="center" title="序号">
          <template v-slot="{ rowIndex }">
            {{ rowIndex + 1 }}
          </template>
        </vxe-table-column>
        <vxe-table-column field="orgCode" align="center" title="组织编码"> </vxe-table-column>
        <vxe-table-column field="orgName" align="center" title="组织名称"> </vxe-table-column>
        <vxe-table-column field="orgTypeName" align="center" title="组织层级"> </vxe-table-column>
        <vxe-table-column field="permissionSearchType" align="center" title="范围">
          <template v-slot="{ row }">
            {{ row.permissionSearchType === "1" ? "当前" : "当前及以下" }}
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <div class="dialog-footer">
      <el-button
        type="danger"
        @click="
          () => {
            $emit('onClose');
          }
        "
        size="small"
        icon="el-icon-close"
        >关闭
      </el-button>
    </div>
  </div>
</template>

<script>
import request from '../../../../../utils/request';

export default {
  components: {},
  props: ['formConfig'],
  created() {
    this.getDetail();
  },
  data() {
    return {
      permissionResource: {},
    };
  },
  methods: {
    // 获取详情数据
    getDetail() {
      request.post('/mdm/mdmDataPermissionController/queryCondition', {
        functionCode: this.formConfig.functionCode,
        listConfigCode: this.formConfig.listConfigCode,
        permissionDimension: this.formConfig.permissionDimension,
        roleCode: this.formConfig.roleCode,
      }).then((res) => {
        this.permissionResource = res.result;
      });
    },
  },
};
</script>

<style lang="less">
.position-view {
  &-basic-title {
    margin-top: 0;
    margin-bottom: 0.3rem;
  }
  &-basic {
    color: #72767b;
    display: flex;
    flex-wrap: wrap;
    .position-view-item {
      width: 33%;
      margin-bottom: 3rem;
    }
  }
}
</style>
