<template>
  <div class="selectListContent">
    <div v-if="type !== 'permissionDimension'" class="select-list-title">
      {{ title }}
    </div>
    <div v-else class="select-list-title">
      <slot />
    </div>
    <div>
      <el-input
        v-model="message"
        placeholder="请输入搜索内容"
        suffix-icon="el-icon-search"
        @input="search"
        clearable
      ></el-input>
    </div>
    <div v-if="dataList.length !== 0" class="seleceListBox">
      <ul>
        <li
          v-for="(item, index) in dataList"
          class="overflow-hidden"
          :class="{ 'selectList-selected': item.id === selectRow.id, 'selected': item.functionAuthorized === '1' }"
          :key="index"
          @click="() => selectItem(item)"
        >
          {{ item[label] }}
        </li>
      </ul>
    </div>
    <div v-else class="selectList-empty">
      当前暂无数据
    </div>
  </div>
</template>

<script>
import request from '../../../../../utils/request';
import debounce from '../../../../../utils/debounce';

export default {
  props: {
    url: String,
    title: String,
    label: String,
    selectFirst: Boolean,
    type: String,
    propsMessage: String,
  },
  data() {
    return {
      originDataList: [], // 远程获取的数据
      dataList: [],
      message: '',
      selectRow: {},
    };
  },
  watch: {
    url() {
      this.getListData({});
    },
  },
  async created() {
    if (this.type !== 'listPage') {
      await this.getListData();
    }
  },
  mounted() {},
  methods: {
    async getListData(params) {
      const data = params || {};
      console.log(this.url);
      const res = await request.post(this.url, data);
      if (res.success) {
        this.originDataList = res.result.map((v) => {
          const item = v;
          item.id = item.id || item.functionCode;
          return item;
        });
        this.dataList = this.originDataList;
        if (this.type === 'listPage') {
          console.log(this.selectFirst);
          if (this.selectFirst) {
            this.selectRow = { ...this.dataList[0] };
            console.log(this.selectRow);
            this.$emit('select', this.dataList[0]);
          }
        }
        if (this.propsMessage && this.type === 'permissionDimension') {
          this.$nextTick(() => {
            this.message = this.dataList.find((a) => a.roleCode === this.propsMessage)[this.label] || '';
            this.search();
            this.selectItem(this.dataList.find((a) => a.roleCode === this.propsMessage) || {});
          });
        }
      }
      return true;
    },
    selectItem(item) {
      this.selectRow = item;
      this.$emit('select', item);
    },
    // eslint-disable-next-line
    search: debounce(function() {
      this.dataList = this.originDataList.filter(
        (item) => item[this.label].toLowerCase().indexOf(this.message.toLowerCase()) >= 0,
      );
    }, 500),
  },
};
</script>

<style lang="less">
.selectListContent {
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid #e4e4e4;
  .select-list-title {
    padding-left: 10px;
    font-size: 16px;
    color: #606266;
    background-color: #edeff0;
    height: 50px;
    line-height: 50px;
  }
  .seleceListBox {
    height: 600px;
    overflow: auto;
    ul {
      padding: 0;
      list-style: none;
      li {
        height: 30px;
        cursor: pointer;
        font-size: 12px;
        color: #606266;
        padding: 0 10px;
        line-height: 30px;
        &:hover {
          background-color: #f1eeee;
        }
      }
      .selectList-selected {
        background-color: #f1eeee;
      }
      .overflow-hidden {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .selectList-empty {
    text-align: center;
    padding: 10px 0;
    font-size: 12px;
    color: #a7a7a7;
  }
}
</style>
